
/* eslint-disable global-require */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/contact',
      load: () => import(/* webpackChunkName: 'contact' */ './contact'),
    },
    {
      path: '/about',
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    {
      path: '/privacy',
      load: () => import(/* webpackChunkName: 'privacy' */ './privacy'),
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login'),
    },
    {
      path: '/siteadmin',
      load: () => import(/* webpackChunkName: 'dashboard' */ './site-admin/dashboard'),
    },
    {
      path: '/inputform',
      load: () => import(/* webpackChunkName: 'inputform' */ './site-admin/inputform'),
    },
    {
      path: '/table',
      load: () => import(/* webpackChunkName: 'table' */ './site-admin/table'),
    },

    {
      path: '/siteadmin/users',
      load: () => import(/* webpackChunkName: 'users' */ './site-admin/users'),
    },
    {
      path: '/siteadmin/partners',
      load: () => import(/* webpackChunkName: 'partners' */ './site-admin/partners'),
    },
    {
      path: '/siteadmin/category',
      load: () => import(/* webpackChunkName: 'category' */ './site-admin/category'),
    },
    {
      path: '/siteadmin/sub-category',
      load: () => import(/* webpackChunkName: 'subCategory' */ './site-admin/subCategory'),
    },
    {
      path: '/siteadmin/jobs',
      load: () => import(/* webpackChunkName: 'booking' */ './site-admin/booking')
    },
    {
      path: '/siteadmin/category/add',
      load: () => import(/* webpackChunkName: 'addCategory' */ './site-admin/addCategory')
    },
    {
      path: '/siteadmin/sub-category/add',
      load: () => import(/* webpackChunkName: 'addSubCategory' */ './site-admin/addSubCategory')
    },
    {
      path: '/siteadmin/:from/view/:id',
      load: () => import(/* webpackChunkName: 'viewBooking' */ './site-admin/viewBooking')
    },
    {
      path: '/siteadmin/users/:id',
      load: () => import(/* webpackChunkName: 'editUser' */ './site-admin/editUser')
    },
    {
      path: '/siteadmin/partners/:id',
      load: () => import(/* webpackChunkName: 'editPartner' */ './site-admin/editPartner')
    },
    {
      path: '/siteadmin/category/edit/:id',
      load: () => import(/* webpackChunkName: 'editCategory' */ './site-admin/editCategory')
    },
    {
      path: '/siteadmin/sub-category/edit/:id',
      load: () => import(/* webpackChunkName: 'editSubCategory' */ './site-admin/editSubCategory')
    },
    {
      path: '/siteadmin/promo-code/list',
      load: () => import(/* webpackChunkName: 'promoCodeList' */ './site-admin/promoCode/promoCodeList')
    },
    {
      path: '/siteadmin/promo-code/add',
      load: () => import(/* webpackChunkName: 'addPromoCode' */ './site-admin/promoCode/addPromoCode')
    },
    {
      path: '/siteadmin/promo-code/edit/:id',
      load: () => import(/* webpackChunkName: 'editPromoCode' */ './site-admin/promoCode/editPromoCode')
    },
    {
      path: '/siteadmin/completed-jobs',
      load: () => import(/* webpackChunkName: 'completedBooking' */ './site-admin/completedBooking')
    },
    {
      path: '/siteadmin/cancelled-jobs',
      load: () => import(/* webpackChunkName: 'cancelledBooking' */ './site-admin/cancelledBooking')
    },
    {
      path: '/siteadmin/currency',
      load: () => import(/* webpackChunkName: 'currency' */ './site-admin/currency')
    },
    {
      path: '/siteadmin/settings/site',
      load: () => import(/* webpackChunkName: 'siteSettings' */ './site-admin/siteSettings')
    },
    {
      path: '/siteadmin/change/admin',
      load: () => import(/* webpackChunkName: 'changeAdmin' */ './site-admin/changeAdmin')
    },
    {
      path: '/siteadmin/notifications',
      load: () => import(/* webpackChunkName: 'manageNotifications' */ './site-admin/manageNotifications')
    },
    {
      path: '/siteadmin/cancel-reasons',
      load: () => import(/* webpackChunkName: 'manageCancelReasons' */ './site-admin/manageCancelReasons')
    },
    {
      path: '/siteadmin/ratings',
      load: () => import(/* webpackChunkName: 'ratings' */ './site-admin/ratings')
    },
    {
      path: '/siteadmin/cancel-reasons/add',
      load: () => import(/* webpackChunkName: 'addCancelReason' */ './site-admin/addCancelReason')
    },
    {
      path: '/siteadmin/cancel-reasons/edit/:id',
      load: () => import(/* webpackChunkName: 'editCancelReason' */ './site-admin/editCancelReason')
    },
    {
      path: '/siteadmin/add-location',
      load: () => import(/* webpackChunkName: 'addLocation' */ './site-admin/addLocation')
    },
    {
      path: '/siteadmin/location',
      load: () => import(/* webpackChunkName: 'manageLocationList' */ './site-admin/manageLocationList')
    },
    {
      path: '/siteadmin/edit-location/:id',
      load: () => import(/* webpackChunkName: 'editLocation' */ './site-admin/editLocation')
    },
    {
      path: '/siteadmin/homepage/banner',
      load: () => import(/* webpackChunkName: 'homeSettings' */ './site-admin/homeSettings')
    },
    {
      path: '/siteadmin/homepage/topfeature',
      load: () => import(/* webpackChunkName: 'categorySettings' */ './site-admin/categorySettings')
    },
    {
      path: '/siteadmin/homepage/category',
      load: () => import(/* webpackChunkName: 'citySettings' */ './site-admin/citySettings')
    },
    {
      path: '/siteadmin/homepage/user',
      load: () => import(/* webpackChunkName: 'safetySettings' */ './site-admin/safetySettings')
    },
    {
      path: '/siteadmin/homepage/partner',
      load: () => import(/* webpackChunkName: 'signupSettings' */ './site-admin/signupSettings')
    },
    {
      path: '/siteadmin/homepage/footer',
      load: () => import(/* webpackChunkName: 'footerSettings' */ './site-admin/footerSettings')
    },
    {
      path: '/siteadmin/payout',
      load: () => import(/* webpackChunkName: 'autoPayout' */ './site-admin/autoPayout')
    },
    {
      path: '/siteadmin/failed-payout',
      load: () => import(/* webpackChunkName: 'failedPayout' */ './site-admin/failedPayout')
    },
    {
      path: '/siteadmin/staticpage/manage',
      load: () => import(/* webpackChunkName: 'staticPage' */ './site-admin/staticPage')
    },
    {
      path: '/siteadmin/contentpage/manage',
      load: () => import(/* webpackChunkName: 'contentPage' */ './site-admin/contentPage')
    },
    {
      path: '/siteadmin/contentpage/add',
      load: () => import(/* webpackChunkName: 'addContentPage' */ './site-admin/addContentPage')
    },
    {
      path: '/siteadmin/contentpage/edit/:pageId',
      load: () => import(/* webpackChunkName: 'editContentPage' */ './site-admin/editContentPage')
    },
    {
      path: '/support',
      load: () => import(/* webpackChunkName: 'support' */ './static/support')
    },
    {
      path: '/partner/privacy-policy',
      load: () => import(/* webpackChunkName: 'privacy' */ './static/partnerPrivacyPolicy')
    },
    {
      path: '/siteadmin/staticpage/edit/:pageId',
      load: () => import(/* webpackChunkName: 'editStaticPage' */ './site-admin/editStaticPage')
    },
    {
      path: '/user',
      load: () => import(/* webpackChunkName: 'user' */ './static/user'),
    },
    {
      path: '/partner',
      load: () => import(/* webpackChunkName: 'partner' */ './static/partner'),
    },
    {
      path: '/legal',
      load: () => import(/* webpackChunkName: 'legal' */ './static/legal')
    },
    {
      path: '/privacy-policy',
      load: () => import(/* webpackChunkName: 'privacyPolicy' */ './static/privacyPolicy')
    },
    {
      path: '/special-course',
      load: () => import(/* webpackChunkName: 'specialCourse' */ './static/specialCourse')
    },
    {
      path: '/siteadmin/pricing/list',
      load: () => import(/* webpackChunkName: 'pricingList' */ './site-admin/pricing/pricingList'),
    },
    {
      path: '/siteadmin/pricing/add',
      load: () => import(/* webpackChunkName: 'addPricing' */ './site-admin/pricing/addPricing')
    },
    {
      path: '/siteadmin/pricing/edit/:id',
      load: () => import(/* webpackChunkName: 'editPricing' */ './site-admin/pricing/editPricing')
    },
    {
      path: '/siteadmin/admin-roles',
      load: () => import(/* webpackChunkName: 'adminRoles' */ './site-admin/adminRoles')
    },
    {
      path: '/siteadmin/admin-users',
      load: () => import(/* webpackChunkName: 'adminUser' */ './site-admin/adminUser')
    },
    {
      path: '/siteadmin/precaution-notification',
      load: () => import(/* webpackChunkName: 'updatePrecautionNotification' */ './site-admin/precautionNotification/updatePrecautionNotification')
    },
    {
      path: '/siteadmin/:from/chat-message/:id',
      load: () => import(/* webpackChunkName: 'chatMessage' */ './site-admin/chatMessage')
    },
    {
      path: '/siteadmin/settings/mobile-app',
      load: () => import(/* webpackChunkName: 'mobileSettings' */ './site-admin/mobileSettings')
    },
    {
      path: '/siteadmin/tracking',
      load: () => import(/* webpackChunkName: 'tracking' */ './site-admin/tracking')
    },
    {
      path: '/siteadmin/sms-methods',
      load: () => import(/* webpackChunkName: 'sms-methods' */ './site-admin/smsMethod')
    },
    {
      path: '/siteadmin/sms-method/edit/:id',
      load: () => import(/* webpackChunkName: 'edit-sms-methods'' */ './site-admin/smsMethod/editSmsMethod')
    },


    //****** It should be before last route.
    {
      path: '/:pageUrl',
      load: () => import(/* webpackChunkName: 'page' */ './page')
    },
    //******* */

    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
